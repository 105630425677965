<template>
    <div class="container-fluid">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" clearable dense :disabled="disabled_region == true"  @change="(event) => updateOfficeDropdown(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch" clearable dense :disabled="disabled_office == true" @change="(event) => updateSalesmanDropdown(event)" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-2 col-4">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="type_id" item-text="type_text" label="Type" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-2 col-8">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mt-3" color="primary" elevation="4" small rounded @click="submitData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-data-table :headers="headersBranch" :items="itemKPIBranchLists" class="elevation-1" :search="searchBranch" :loading="loadingDetail" loading-text="Loading... Please wait a moment" dense>
                            <template v-slot:top>
                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                        <div class="d-flex w-100">
                                            <h6 class="indigo-text m-0 primary--text">Summary </h6>
                                            <v-spacer></v-spacer>
                                            <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchBranch" append-icon="mdi-magnify" label="search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                            <template v-slot:[`item.percentage`]="{ item }">
                                {{ item.percentage }} %
                            </template>
                            <template v-slot:[`item.detailItem`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip @click="showDetailsBranch(item)" color="blue" small link outlined v-on="on">Details</v-chip>
                                    </template>
                                    <span>Show Details</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

             <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemKPILists" class="elevation-1" :search="search" :loading="loadingDetail" loading-text="Loading... Please wait a moment" dense>
                            <template v-slot:top>
                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                        <div class="d-flex w-100">
                                            <h6 class="indigo-text m-0 primary--text">Detail </h6>
                                            <v-spacer></v-spacer>
                                            <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="search" append-icon="mdi-magnify" label="search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                            <template v-slot:[`item.percentage`]="{ item }">
                                {{ item.percentage }} %
                            </template>
                            <template v-slot:[`item.detailItem`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip @click="showDetails(item)" color="blue" small link outlined v-on="on">Details</v-chip>
                                    </template>
                                    <span>Show Details</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalLabel">KPI Detail of {{ detailModalTitle }}</h5>
                </div>
                <div class="modal-body">
                            
                    <v-data-table :headers="headersDetails" :items="itemKPIDetailsLists" class="elevation-1" :loading="loadingDetailItem" loading-text="Loading... Please wait a moment" dense>
                    </v-data-table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalLabel">KPI Detail of {{ detailModalTitle }}</h5>
                </div>
                <div class="modal-body">
                            
                    <v-data-table :headers="headersDetails" :items="itemKPIDetailsLists" class="elevation-1" :loading="loadingDetailItem" loading-text="Loading... Please wait a moment" dense>
                    </v-data-table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-xl" id="detailModalBranch" tabindex="-1" aria-labelledby="detailModalBranchLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalBranchLabel">KPI Detail of {{ detailModalBranchTitle }}</h5>
                </div>
                <div class="modal-body">
                            
                    <v-data-table :headers="headersBranchDetails" :items="itemKPIBranchDetailsLists" class="elevation-1" :loading="loadingBranchDetailItem" loading-text="Loading... Please wait a moment" dense>
                    </v-data-table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'KPI',
                disabled: false,
                href: '#',
                },
                {
                text: 'KPI Analysis',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            officeModel: '',
            officeLists: [],
            salesModel: '',
            salesLists: [],
            typeModel: '',
            typeLists: [
                {
                    type_id: '',
                    type_text: 'All'
                },
                {
                    type_id: 'Y',
                    type_text: 'Project'
                },
                {
                    type_id: 'N',
                    type_text: 'Non Project'
                },
            ],
            modalDateFrom: false,
            dateFrom: '',
            modalDateTo: false,
            dateTo: '',
            disabled_region: true,
            disabled_office: true,
            disabled_salesman: true,
            itemKPILists: [],
            itemKPIBranchLists: [],
            itemKPIDetailsLists: [],
            itemKPIBranchDetailsLists:[],
            headersBranch: [
                { text: 'PERIOD', value: 'period', align: 'right', width: '6%'},
                { text: 'BRANCH', value: 'office', align: 'left'},
                { text: 'TARGET(IDR)', value: 'kpi_target', align: 'right'},
                { text: 'ACHIEVMENT(IDR)', value: 'kpi_achievment', align: 'right'},
                { text: 'ACHIEVMENT(%)', value: 'percentage', align: 'right'},
                { text: 'TOTAL POINTS', value: 'totPoint', align: 'right'},
                { text: '', value: 'detailItem', align: 'center'}
            ],
            headers: [
                { text: 'PERIOD', value: 'period', align: 'right', width: '6%'},
                { text: 'BRANCH', value: 'office', align: 'left'},
                { text: 'SALESMAN', value: 'namasales', align: 'left'},
                { text: 'TARGET(IDR)', value: 'kpi_target', align: 'right'},
                { text: 'ACHIEVMENT(IDR)', value: 'kpi_achievment', align: 'right'},
                { text: 'ACHIEVMENT(%)', value: 'percentage', align: 'right'},
                { text: 'TOTAL POINTS', value: 'totPoint', align: 'right'},
                { text: '', value: 'detailItem', align: 'center'}
            ],
            headersBranchDetails: [
                { text: 'KPI', value: 'kpi', align: 'left'},
                { text: 'CATEGORY', value: 'category', align: 'left'},
                { text: 'TARGET', value: 'target_val', align: 'right'},
                { text: 'VALUE', value: 'bobot', align: 'right'},
                { text: 'ACH. TOTAL(IDR)', value: 'nilai', align: 'right'},
                { text: 'ACH. WEIGHT(KG)', value: 'berat', align: 'right'},
                { text: 'TOTAL POINTS', value: 'totPoint', align: 'right'}
            ],
            headersDetails: [
                { text: 'KPI', value: 'kpi', align: 'left'},
                { text: 'CATEGORY', value: 'category', align: 'left'},
                { text: 'TARGET', value: 'target_val', align: 'right'},
                { text: 'VALUE', value: 'bobot', align: 'right'},
                { text: 'ACH. TOTAL(IDR)', value: 'nilai', align: 'right'},
                { text: 'ACH. WEIGHT(KG)', value: 'berat', align: 'right'},
                { text: 'TOTAL POINTS', value: 'totPoint', align: 'right'}
            ],
            search: '',
            searchBranch: '',
            loadingDetail: false,
            loadingDetailItem: false,
            loadingBranchDetailItem: false,
            dateFromFormatted: '',
            dateToFormatted: '',
            detailModalBranchTitle: '',
            detailModalTitle: '',
            userGroup: null,
            appl_id: 'ANALYSIS'
        }
    },
    async mounted(){

        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";

            // this.dateFrom = this.currentPeriod()
            // this.dateTo = this.currentPeriod()

            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }

                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }

                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
            
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        updateOfficeDropdown(id){

            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

        updateSalesmanDropdown(id){

            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.salesLists = res.data.sales
                    this.disabled_salesman = false

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.salesLists = res.data.sales
                    this.disabled_salesman = false

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

        async submitData() {

            if(this.regionModel == ''){

                Swal.fire({
                    text: 'Please Choose Region',
                    icon: 'warning',
                })

                return false

            }

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else {
                this.dateToFormatted = ''
            }

            this.$store.dispatch('setOverlay', true)
            this.loadingDetail = true
            this.itemKPILists = []
            this.itemKPIBranchLists = []
            document.querySelector(".cardGraph").style.display = "block";

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getDataBranch`, { 
                
                region: this.regionModel ? this.regionModel : '',
                office: this.officeModel ? this.officeModel : '',
                sales: this.salesModel ? this.salesModel : '',
                type: this.typeModel ? this.typeModel : '',
                dateFrom: this.dateFrom ? this.dateFromFormatted : '',
                dateTo: this.dateTo ? this.dateToFormatted : ''
                
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }
        )
        .then(res => {

            if(res.data.result.length != 0){
                // this.$store.dispatch('setOverlay', false)
                // this.loadingDetail = false
                this.itemKPIBranchLists = res.data.result
            } else {
                // this.$store.dispatch('setOverlay', false)
                // document.querySelector(".cardGraph").style.display = "none";
                // Swal.fire({
                //     text: 'Data not found !',
                //     icon: 'warning',
                // })
            }

        })
        // .catch(err => {

        //     this.$store.dispatch('setOverlay', false)
        //     if(err.response.status == '401'){
        //         this.$router.push('/');
        //     }

        // })
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getData`, { 
                
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    dateFrom: this.dateFrom ? this.dateFromFormatted : '',
                    dateTo: this.dateTo ? this.dateToFormatted : ''
                    
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.result.length != 0){
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDetail = false
                    this.itemKPILists = res.data.result
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        filterText (value, search , item) {
            return value != null &&
            search  != null &&
            typeof value === 'string' &&
            value.toString().indexOf(search) !== -1
        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        async showDetails(id){

            this.detailModalTitle = 'Salesman : ' + id.namasales + ' Period : ' + id.period

            $('#detailModal').modal('show')
            this.itemKPIDetailsLists = []
            this.loadingDetailItem = true
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getDataDetails`, { 
                
                    sales: id.salesid,
                    period: id.period
                    
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetailItem = false
                this.itemKPIDetailsLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async showDetailsBranch(id){

            this.detailModalBranchTitle = 'Branch : ' + id.office + ' Period : ' + id.period

            $('#detailModalBranch').modal('show')
            this.itemKPIBranchDetailsLists = []
            this.loadingBranchDetailItem = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/KPIAnalysis/getDataDetailsBranch`, { 
                
                    salesid: this.salesModel ? this.salesModel : '',
                    office_id: id.office_id,
                    period: id.period
                    
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingBranchDetailItem = false
                this.itemKPIBranchDetailsLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

            },

        currentPeriod() {

            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        }

    }
    
}
</script>

<style scoped>

.v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }
    
</style>